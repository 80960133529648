import React, { ReactElement, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { AnalyticsApi } from "../../../../controller/analytics-api";
import { useUserData } from "../../../hooks/use-user-data";

export const PvCounter =
    process.env.NODE_ENV === "production"
        ? ProductionPvCounter
        : DevelopmentPvCounter;

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

function ProductionPvCounter(): ReactElement {
    const location = useLocation();
    const userData = useUserData();
    const lastPvRef = useRef({ url: "", username: "" });

    useEffect(() => {
        const lastPv = lastPvRef.current;
        const username = userData.email;
        if (!username) {
            return;
        }

        const url = window.location.origin + location.pathname;
        if (url === lastPv.url && username === lastPv.username) {
            return;
        }

        lastPv.url = url;
        lastPv.username = username ?? "";
        AnalyticsApi.recordPageView(username, url).catch((error) => {
            console.error(error);
        });
    }, [location.pathname, userData.email]);

    return <></>;
}

function DevelopmentPvCounter(): ReactElement {
    return <></>;
}

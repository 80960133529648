import {
    Cache,
    IdbCache,
    InMemoryCache,
    Logger,
    LoggingCache,
} from "@sereca/frontend-api-client";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Auth } from "../../../../controller/auth";
import {
    Database,
    DatabaseContext,
    createDatabase,
} from "../../../../database.generated";
import { useUserData } from "../../../hooks/use-user-data";
import { initialCacheRecords } from "../../../initial-cache-records";

declare const process: { readonly env: Record<string, string | undefined> };

export namespace DatabaseProvider {
    /**
     * {@link DatabaseProvider} コンポーネントのプロパティ定義
     */
    export interface Props {
        /** 子要素 */
        readonly children?: ReactNode | undefined;
    }
}

/**
 * このコンポーネントの子要素内で `useDatabase()` を利用できるようにします。
 *
 * @param props プロパティ
 * @returns 描画内容
 */
export function DatabaseProvider({
    children,
}: DatabaseProvider.Props): JSX.Element {
    const userData = useUserData();
    const [database, setDatabase] = useState<Database>();
    const namespace = useMemo(
        () =>
            userData.groups
                .filter((g) => g.endsWith("@user"))
                .map((g) => g.slice(0, -"@user".length))
                .join(";"),
        [userData.groups],
    );

    // データベース オブジェクトを初期化する
    useEffect(() => {
        const logger: Logger = {
            /* eslint-disable no-console */
            debug:
                process.env.NODE_ENV === "production"
                    ? undefined
                    : console.debug,
            error: console.error,
            info: console.info,
            warn: console.warn,
            /* eslint-enable no-console */
        };
        const cache: Cache =
            process.env.NODE_ENV === "production"
                ? new InMemoryCache({
                      permanentCache: new IdbCache({ logger, namespace }),
                  })
                : new LoggingCache({
                      cache: new InMemoryCache({
                          initialCacheRecords,
                          permanentCache: initialCacheRecords
                              ? undefined
                              : new IdbCache({ logger, namespace }),
                      }),
                      logger,
                  });
        const database = createDatabase(
            process.env.SERECA_APIWS_ENDPOINT || "/apiws",
            {
                async fetchAuthToken() {
                    const session = await Auth.getCurrentSession();
                    if (session == null) {
                        throw new Error("401 Unauthorized");
                    }
                    return session.idToken;
                },
            },
            {
                cache,
                logger,
                timeout: 30000,
            },
        );

        setDatabase(database);

        return () => {
            database.close().catch(() => {
                // 無視
            });

            setDatabase(undefined);
        };
    }, [namespace]);

    // 描画する
    if (database === undefined) {
        return <></>;
    }
    return (
        <DatabaseContext.Provider value={database}>
            {children}
        </DatabaseContext.Provider>
    );
}

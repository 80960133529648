import React, { lazy } from "react";
import {
    BrowserRouter,
    Navigate,
    Route,
    Routes,
    useLocation,
} from "react-router-dom";
import { PageTemplate } from "./common/page-template";
import { PvCounter } from "./common/pv-counter";

//------------------------------------------------------------------------------
// 製品化済みの機能
const HelpPage = lazy(async () => import("./pages/help-page"));
const LogoutPage = lazy(async () => import("./pages/logout-page"));
const NodePage = lazy(async () => import("./pages/node-page"));
const TopNodePage = lazy(async () => import("./pages/top-node-page"));

// 特権ページ群
const AdminRoutes = lazy(async () => import("./main-admin-routes"));
const VendorRoutes = lazy(async () => import("./main-vendor-routes"));
// 製品化済みの機能、ここまで
//------------------------------------------------------------------------------

//------------------------------------------------------------------------------
// 価値検証中 (🚨フラグで除外できるように定義してください🚨)
//------------------------------------------------------------------------------
// 書き方:
//   フラグが有効なときだけインポートしてください。
//   これにより、フラグが無効な場合はビルド結果に含まれなくなります。
// 例示:
//   const DataExport =
//       process.env.SERECA_FLAG_DATA_EXPORT_FEATURE === "1"
//           ? lazy(async () => import("./features/data-export"))
//           : undefined;
//------------------------------------------------------------------------------
const DashboardPage =
    process.env.SERECA_FLAG_AQUA_DASHBOARD_PAGE === "1"
        ? lazy(async () => import("./pages/dashboard-page"))
        : undefined;
const DevRoutes =
    process.env.NODE_ENV !== "production"
        ? lazy(async () => import("./main-dev-routes"))
        : undefined;
//------------------------------------------------------------------------------

/**
 * ログイン中に表示する内容
 */
export function MainRoutes(): JSX.Element {
    return (
        <BrowserRouter>
            <PvCounter />
            <Routes>
                <Route path="/" element={<PageTemplate />}>
                    {/* NodePage を表示する */}
                    <Route index element={<Navigate replace to="/nodes" />} />

                    {/* ログイン URL から来る場合があるのでリダイレクト */}
                    <Route
                        path="login"
                        element={<Navigate replace to="/nodes" />}
                    />

                    {/* ユーザー登録等、ログアウト時に使うページならログアウト */}
                    <Route path="forgot-password/*" element={<LogoutPage />} />
                    <Route path="sign-up" element={<LogoutPage />} />
                    <Route path="password-reset" element={<LogoutPage />} />
                    <Route path="password-register" element={<LogoutPage />} />
                    <Route path="user-register" element={<LogoutPage />} />

                    {/* 一般ユーザー向け画面 */}
                    <Route path="help/*" element={<HelpPage />} />
                    <Route path="nodes" element={<TopNodePage />} />
                    <Route path="nodes/:id" element={<NodePage />} />
                    <Route path="nodes/:id/:mode/*" element={<NodePage />} />

                    {/* 一般ユーザー向け画面 (価値検証中) */}
                    {DashboardPage && (
                        <>
                            <Route
                                path="/dashboard"
                                element={<DashboardPage mode="view" />}
                            />
                            <Route
                                path="/dashboard/layout"
                                element={<DashboardPage mode="edit" />}
                            />
                        </>
                    )}

                    {/*
                     * 管理者ユーザー向け画面 (利用には各テナントの `*@root` 権限が必要)
                     * <AdminRoutes> の中に追加してください
                     */}
                    <Route path="admin/*" element={<AdminRoutes />} />

                    {/*
                     * 明電舎の管理者向け画面 (利用には `maintenance` 権限など、各種権限が必要)
                     * <VendorRoutes> の中に追加してください
                     */}
                    <Route path="vendor/*" element={<VendorRoutes />} />

                    {/*
                     * 我ら開発者向け画面 (本番ビルドには含まれない)
                     * <DevRoutes> の中に追加してください
                     */}
                    {DevRoutes && (
                        <Route path="developers/*" element={<DevRoutes />} />
                    )}

                    {/* 後方互換性維持のためのリダイレクト (2025-04-01 以降に削除) */}
                    {redirect("admin", "node-edit")}
                    {redirect("admin", "part-edit")}
                    {redirect("admin", "type-edit")}
                    {redirect("developers", "datum-list")}
                    {redirect("developers", "components")}
                    {redirect("developers", "cicd")}
                    {redirect("vendor", "definition-sheets")}
                    {redirect("vendor", "incidents")}
                    {redirect("vendor", "invitation")}
                    {redirect("vendor", "microsoft-teams-integrations")}
                    {redirect("vendor", "tenant-list")}
                    {redirect("vendor", "user-management")}
                    {redirect("vendor", "user-support-cases")}
                    {/* (2025-04-01 以降に削除; ここまで) */}

                    {/* 404 Not Found */}
                    <Route path="*" element={<div>404 Not Found</div>} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

function redirect(prefix: string, target: string): JSX.Element {
    return (
        <Route
            path={`/${target}/*`}
            element={<RedirectWithPrefix prefix={`/${prefix}`} />}
        />
    );
}

function RedirectWithPrefix({
    prefix,
}: {
    readonly prefix: string;
}): JSX.Element {
    const location = useLocation();
    const targetPath = `${prefix}${location.pathname}${location.search}${location.hash}`;
    return <Navigate replace to={targetPath} />;
}

import { AnyPrimaryKey } from "@sereca/protocol";

// テストビルドのみ、ビルド時にオブジェクトが与えられる
declare const __INITIAL_CACHE_RECORDS__:
    | Record<
          string,
          readonly [id: AnyPrimaryKey, record: Record<string, unknown>][]
      >
    | undefined;

// テストデータ
export const initialCacheRecords =
    typeof __INITIAL_CACHE_RECORDS__ === "object"
        ? __INITIAL_CACHE_RECORDS__
        : undefined;

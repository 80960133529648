import {
    CssBaseline,
    GlobalStyles,
    GlobalStylesProps,
    StyledEngineProvider,
} from "@mui/material";
import { blue, blueGrey, grey } from "@mui/material/colors";
import React, { Suspense } from "react";
import { DatabaseProvider } from "./components/common/database-provider";
import { ErrorBoundary } from "./components/common/error-boundary";
import { UserDataProvider } from "./components/common/user-data-provider";
import { LoginRoutes } from "./components/login-routes";
import { MainRoutes } from "./components/main-routes";
import { ThemeProvider } from "./hooks/use-theme-parameter";

export function App(): JSX.Element {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider>
                <CssBaseline />
                <GlobalStyles styles={BaseCss} />
                <ErrorBoundary>
                    <UserDataProvider>
                        {{
                            // ログイン済みの場合
                            authenticated: (
                                <DatabaseProvider>
                                    <Suspense fallback={null}>
                                        <MainRoutes />
                                    </Suspense>
                                </DatabaseProvider>
                            ),

                            // 未ログインの場合
                            unauthenticated: (
                                <Suspense fallback={null}>
                                    <LoginRoutes />
                                </Suspense>
                            ),
                        }}
                    </UserDataProvider>
                </ErrorBoundary>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

const BaseCss: GlobalStylesProps["styles"] = (theme) => ({
    "html, body, #app, body > div:first-of-type": {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        contain: "strict",
    },

    a: {
        color: theme.palette.mode === "dark" ? blue[200] : blue[500],
        textDecoration: "none",
    },

    ":root": {
        "--sb-track-color":
            theme.palette.mode === "dark" ? grey[900] : grey[100],
        "--sb-thumb-color":
            theme.palette.mode === "dark" ? blueGrey[800] : blueGrey[200],
        "--sb-size": "12px",
        scrollbarColor: "var(--sb-thumb-color) var(--sb-track-color)",
    },

    "::-webkit-scrollbar": {
        width: "var(--sb-size)",
        height: "var(--sb-size)",
    },
    "::-webkit-scrollbar-track": {
        background: "var(--sb-track-color)",
        borderRadius: "calc(var(--sb-size) / 2)",
    },
    "::-webkit-scrollbar-thumb": {
        background: "var(--sb-thumb-color)",
        borderRadius: "calc(var(--sb-size) / 2)",
        border: "calc(var(--sb-size) / 6) solid var(--sb-track-color)",
    },
    "::-webkit-scrollbar-corner": {
        background: "var(--sb-track-color)",
    },
});

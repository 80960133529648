import { Alert } from "@mui/material";
import React, { ReactNode } from "react";
import { PrimaryButton } from "../buttons";
import { Circular } from "../circular";
import { MultilineText } from "../multiline-text";

export namespace ErrorBoundary {
    /**
     * {@link ErrorBoundary} コンポーネントのプロパティ定義
     */
    export interface Props {
        /**
         * 子要素
         */
        readonly children?: ReactNode;
    }
}

export class ErrorBoundary extends React.Component<ErrorBoundary.Props, State> {
    state: State = { error: undefined, loading: false };

    override componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.error("致命的なエラー", error, "in", errorInfo.componentStack);

        // 遅延読込のエラーだった場合、新しいデプロイが行われ、ファイル名が変化
        // している可能性がある。1回だけ自動的にリロードする。
        if (isLoadingError(error)) {
            if (isAutoReloadedAlready(error)) {
                console.warn(
                    "This session has experienced an automatic reload already.",
                );
                this.setState({ error });
            } else {
                reload();
                this.setState({ loading: true });
            }
        } else {
            this.setState({ error });
        }
    }

    override render(): React.ReactNode {
        const { children } = this.props;
        const { error, loading } = this.state;

        if (error) {
            return (
                <Alert severity="error">
                    致命的なエラーが発生しました。
                    <br />
                    <br />
                    <MultilineText>{error.message}</MultilineText>
                    <br />
                    <PrimaryButton onClick={reload}>再表示する</PrimaryButton>
                </Alert>
            );
        }

        if (loading) {
            return <Circular />;
        }

        return children;
    }
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

interface State {
    error: Error | undefined;
    loading: boolean;
}

const LastErrorMessage = "b7cf14d7-9d2c-4484-a78c-58f0f08feaf6";

/**
 * エラーが読み込みエラーかどうか判定します。
 *
 * TODO(mysticatea): とりあえず Chromium 系のブラウザであればこれで動作します。
 *                   別のブラウザで動作するかどうかの検証は、まだできていません。
 *
 * @param error エラー
 * @returns エラーが読み込みエラーであれば真
 */
function isLoadingError(error: unknown): error is TypeError {
    return (
        error instanceof TypeError &&
        error.message.startsWith("Failed to fetch dynamically imported module:")
    );
}

/**
 * 既に自動リロードされているかどうか判定します。
 *
 * この関数には副作用があります。
 * エラーメッセージをセッションストレージに保存し、その保存したエラーメッセージと
 * 今回のエラーメッセージが同じかどうか検証しています。
 *
 * @param error エラー
 * @returns 既に自動リロードされていたら真
 */
function isAutoReloadedAlready(error: TypeError): boolean {
    if (error.message === sessionStorage.getItem(LastErrorMessage)) {
        sessionStorage.removeItem(LastErrorMessage);
        return true;
    }

    sessionStorage.setItem(LastErrorMessage, error.message);
    return false;
}

function reload(): void {
    location.reload();
}
